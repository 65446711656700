import { error } from "../../components/common/Toast";
import { JsonSearchResult, Position } from "../Interfaces";
import { stringifyJson } from "./Validator";

export function searchInText(content: string, searchEntity: string, isRegex?: boolean): JsonSearchResult[] | undefined {
    searchEntity = searchEntity.toLowerCase()
    if(!isRegex) {
        content = content.toLowerCase()
        var splContent = content.split("\n");
        var searchResult: JsonSearchResult[] = []

        if(content.includes(searchEntity)) {
            for(let i = 0; i < splContent.length; i ++) {
                if(splContent[i].includes(searchEntity)) {
                    let postions: Position[] = [];
                    for(let j = 0; j < splContent[i].length; j ++) {
                        if(splContent[i].substring(j, (j + searchEntity.length)) === searchEntity)
                            postions.push({
                                start: j,
                                end: (j + searchEntity.length)
                            })
                    }
                    searchResult.push({
                        lineNumber: i,
                        position: postions
                    })
                }
            }
        }
        return searchResult;
    }
}