import React, { SetStateAction, useEffect, useRef, useState } from "react";
import { CodeProp, ViewTypes } from "../../utils/Interfaces";
import { Button, CloseButton, Dropdown, DropdownButton } from "react-bootstrap";
import TreeView from "./TreeView";
import defaults from "../../metadata/defaults.json";
import TextView from "./TextView";
import ToolBar from "./ToolBar";
import { codeDivCss, linesCommonDiv, textAreaTextView } from "../../css/react-styles";
const errorMsg = require("../../metadata/errorMsg.json");

function getViewByType(viewType: string, 
    content: string,
    fullScreenEnabled: boolean, 
    linesToHighlight?: number[], 
    searchEntity?: string) {
    if(viewType === ViewTypes.ReadOnly)
        return <TextView lines={content.split("\n")} linesToHighlight={linesToHighlight} searchEntity={searchEntity} />
    else if(viewType === ViewTypes.ObjectView)
        return <TreeView textContent={content} />
    else if(viewType === ViewTypes.TextView)
        return <textarea style={textAreaTextView(fullScreenEnabled)} value={content}></textarea>
    else return <></>
}

function checkSearchEntityLength(searchEntity: string, 
    toDisplayErrorMessage: React.Dispatch<SetStateAction<"block" | "none">>, 
    errorMessage: React.Dispatch<SetStateAction<string>>,
    setSearchText: React.Dispatch<SetStateAction<string>>) {
    if(searchEntity.length < 3) {
        toDisplayErrorMessage("block");
        errorMessage(errorMsg.DisplayMessage.SearchBoxTextLimit);
    }
    else {
        toDisplayErrorMessage("none");
        errorMessage("")
        setSearchText(searchEntity)
    }
}

export default (props: CodeProp) => {
    const [viewType, setViewType] = useState<string>(props.demandedViewType ? props.demandedViewType : defaults.json.defaultView);
    const [ddText, setDDText] = useState<string>(defaults.json.defaultView);
    const [displaySearch, setDispSearch] = useState<"block" | "none">("none");
    const searchIpFld = useRef<HTMLInputElement>(null);
    const [searchText, setSearchText] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [toDisplayErrorMessage, setToDisplayErrorMessage] = useState<"block" | "none">("none");

    const divRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if(props.readyForScroll) {
            divRef.current?.scrollIntoView({
                behavior: "smooth"
            })
        }
    })

    return props.toShow ? (
        <div style={{
            marginTop: props.fullScreenEnabled ? "0px" : "5%",
            height: props.fullScreenEnabled ? "100vh" : "inherit"
        }}>
            <div style={ codeDivCss(props.fullScreenEnabled) } ref={divRef}>
                <div className="result-header">
                    <h3 style={{
                        textAlign: "left",
                        marginLeft: props.headerLeftMargin ? props.headerLeftMargin : "2em",
                        color: "white"
                    }}>{props.title}</h3>
                    {
                        props.showViewAsButton ? <DropdownButton id="dropdown-basic-button" title={ddText} style={{
                            width: "max-content",
                            marginLeft: "auto",
                            marginRight: "5%"
                        }}> 
                        {
                            defaults.json.views.map(eachItem => {
                                return <Dropdown.Item onClick={() => {
                                    setDDText(eachItem)
                                    setViewType(eachItem)
                                }}>{eachItem}</Dropdown.Item>
                            })
                        }
                        </DropdownButton> : <></>
                    }
                </div>
                <ToolBar 
                    setDDText={setDDText} 
                    setViewType={setViewType} 
                    setDispSearch={setDispSearch} 
                    displaySearch={displaySearch}
                    content={props.content}
                    fullScreenEnabled={ props.fullScreenEnabled ? props.fullScreenEnabled : false }
                    setFullScrennEnabled={ props.setFullScrennEnabled }
                />
                <div style={ linesCommonDiv(props.fullScreenEnabled, (viewType === ViewTypes.ReadOnly)) } className="lines-common-div">
                    <div className="search-root" style={{
                        display: displaySearch
                    }}>
                        <input type="text" ref={searchIpFld} placeholder="Search anything..." className="srch-ip"
                            onChange={(e) => {
                                checkSearchEntityLength(e.target.value, 
                                    setToDisplayErrorMessage, 
                                    setErrorMessage,
                                    setSearchText)
                            } }
                         />
                        <Button className="srch-btn" onClick={() => {
                            if(searchIpFld.current)
                                setSearchText(searchIpFld.current?.value)
                        }}>Search</Button>
                        <div style={{
                            textAlign: "right",
                            fontSize: "10px",
                            display: "inline-block",
                            marginLeft: "10px"
                        }}>
                            <CloseButton style={{
                                marginBottom: "10px",
                                border: "solid 1px",
                                borderRadius: "unset"
                            }} onClick={() => setDispSearch("none") } />
                        </div>
                        <p style={{
                            display: toDisplayErrorMessage,
                            color: "red",
                            marginBottom: "unset",
                            fontSize: "12px"
                        }}>{errorMessage}</p>
                    </div>
                    {
                        getViewByType(viewType, 
                            props.content,
                            props.fullScreenEnabled, 
                            props.linesToHighlight,
                            searchText
                        )
                    }
                </div>
            </div>
        </div>
    ) : <></>
}