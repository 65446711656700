import { useEffect, useState } from "react"
import Footer from "../../components/Footer"
import Header from "../../components/Header"
import Sidebar from "../../components/Sidebar"
import JsonCompare from "../../components/validators/JsonCompare"
import { useEffectCallback } from "../../utils/Utils"
import Description from "../../components/common/Description"
const pages = require('../../metadata/pages.json');

export default () => {
    const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
	const [innerWidth, setInnerWidth] = useState<number>(0);
    const [lowerWidthDisplay, setLowerWidthDisplay] = useState<"none" | "block">("block");
    const [textAlignValue, setTextAlignValue] = useState<"center" | "right">("center")

	useEffect(() => useEffectCallback(setInnerWidth, setLowerWidthDisplay, setTextAlignValue, setIsCollapsed, innerWidth));

    return (
        <div style={{
            height: "fit-content"
          }}>
            <Header lowerWidthDisplay={lowerWidthDisplay} textAlignValue={textAlignValue} />
            <Sidebar isCollapsed={isCollapsed} />
            <JsonCompare />
            <Description 
				aboutDescription={pages.json.compare.description.About} 
				moreAboutJson={pages.json.general.MoreAboutJson} 
				standards={pages.json.general.Standards}
				faqs={pages.json.general.FAQs} />
            <Footer />
        </div>
    )
}