import { searchInText } from "../../utils/core/Misc";
import { JsonSearchResult, Position, TextViewProp } from "../../utils/Interfaces";

function highlightSearchInPre(lineStr: string, searchEntity: string | undefined) {
    var innerHtmlText = {
        __html: lineStr
    }
    if(searchEntity) {
        var searchResultArr: JsonSearchResult[] | undefined = searchInText(lineStr, searchEntity);
        if(searchResultArr && searchResultArr.length !== 0) {
            var searchRs = searchResultArr[0];
            searchRs.position.forEach(eachPos => {
                innerHtmlText.__html = innerHtmlText.__html.replace(lineStr.substring(eachPos.start, eachPos.end), "<span>" + lineStr.substring(eachPos.start, eachPos.end) + "</span>")
            })
        }
    }
    return innerHtmlText
}

export default (props: TextViewProp) => {


    if(props.searchEntity) {
        return (
            <>
            {
                props.lines.map((eachLine, index) => {
                    let classNameVal = "code-span";
                    
                    if(props.linesToHighlight?.includes(index))
                        classNameVal += " code-hightlight-error";
        
                    return (
                        <div style={{
                            display: "flex"
                        }}>
                            <span className={classNameVal}>{index + 1}</span>
                            <code className="code-code">
                                <pre id="hl-pre" dangerouslySetInnerHTML={highlightSearchInPre(eachLine, props.searchEntity)}></pre>
                            </code>
                        </div>
                    )
                })
            }
            </>
        )
    }
    else {
        return (
            <>
            {
                props.lines.map((eachLine, index) => {
                    let classNameVal = "code-span";
                    
                    if(props.linesToHighlight?.includes(index))
                        classNameVal += " code-hightlight-error";
        
                    return (
                        <div style={{
                            display: "flex"
                        }}>
                            <span className={classNameVal}>{index + 1}</span>
                            <code className="code-code">
                                <pre>{eachLine}</pre>
                            </code>
                        </div>
                    )
                })
            }
            </>
        )
    }
}