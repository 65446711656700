import ReactDOM from 'react-dom/client';
import './css/index.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from "./pages/Home";
import Error404 from "./pages/Error404";
import ContactUs from "./pages/ContactUs";
import About from "./pages/About";
import Formatter from "./pages/json/Formatter";
import Compare from "./pages/json/Compare";
import Validator from "./pages/json/Validator";
import './css/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import ToString from './pages/json/ToString';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
	<BrowserRouter>
	    <Routes>
			<Route path="*" element={ <Error404/> } />
			<Route index element={<Formatter />} />
			<Route path="ContactUs" element={<ContactUs />} />
			<Route path="About" element={<About />} />
			<Route path="json">
				<Route path="Compare" element={ <Compare /> } />
				<Route path={ "Format"} element={ <Formatter /> } />
				<Route path={ "Formatter"} element={ <Formatter /> } />
				<Route path={ "Validator"} element={ <Validator /> } />
				<Route path={ "Validate"} element={ <Validator /> } />
				<Route path={ "ToString"} element={ <ToString /> } />
			</Route>
	    </Routes>
	</BrowserRouter>
);