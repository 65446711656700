import React, { Component, useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import defaults from "../metadata/defaults.json";

var count = 0;

export default () => {
	const [text, setText] = useState("")

	useEffect(() => {
		setTimeout(() => {
			if(count < defaults['404Text'].length) {
				setText(text + defaults['404Text'][count])
				count += 1
			}
			else if(count === defaults['404Text'].length) {
				setTimeout(() => {
					setText(text + "_")
					count += 1
				}, 250);
			}
			else if(count === defaults['404Text'].length + 1) {
				setTimeout(() => {
					setText(text.substring(0, text.length - 1))
					count -= 1
				}, 300);
			}
		}, 20)
	})
  
	return (
		<div style={{
			position:"relative",
			top: "50%",
			left: "10%"
		}}>
			<h2 style={{
				fontFamily: "monospace"
			}}>{text}</h2>
			<Button href='/'>HOME</Button>
		</div>
	)
}