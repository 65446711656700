import { DifferenceSummaryProp } from "../../utils/Interfaces";

function getInlineItem(text: string) {
    return (
        <div>
            {/* <span style={{
                marginRight: "1em"
            }}><input type="checkbox" defaultChecked={true} /></span> */}
            <span>{text}</span>
        </div>
    )
}

export default (props: DifferenceSummaryProp) => {
    return (
        <div className="diff-sum-rt-div">
            { getInlineItem("Found " + (props.difference.notIn1.length + props.difference.notIn2.length) + " differences") }
            { getInlineItem("Total missing items in JSON 1 : " + props.difference.notIn1.length) }
            { getInlineItem("Total missing items in JSON 2 : " + props.difference.notIn2.length) }
        </div>
    )
}