import { Button, Form } from 'react-bootstrap';
import * as Head from '../components/common/Header';
import Footer from '../components/Footer';
import Header from '../components/Header';
import { useEffect, useRef, useState } from 'react';
import Loader from '../components/common/Loader';
import { useEffectCallback } from '../utils/Utils';

function contactUsIdentity() {
	return (
		<div style={{
			position: "relative",
			top: "50%",
			textAlign: "left",
			paddingLeft: "20%",
		}} className='txt-col'>
			<div style={{
				fontSize: "32px",
				fontWeight: "600"
			}}>Contact Us</div>
			<p style={{
				marginTop: "2em"
			}}>Need to react out to us? Fill the form to get your queries prioritized</p>
		</div>
	)
}

function handleSubmit(setToDisplayMsg: (toDisplayMsg: "block" | "none") => void,
	nameRef: React.RefObject<HTMLInputElement>, emailRef: React.RefObject<HTMLInputElement>,
	msgRef: React.RefObject<HTMLTextAreaElement>) {
	
		if(nameRef.current !== null)
		nameRef.current.value = "";
	if(emailRef.current !== null)
		emailRef.current.value = "";
	if(msgRef.current !== null)
		msgRef.current.value = "";

	setToDisplayMsg("block");
}

function contactUsForm(toDisplayMsg: "block" | "none", 
	setToDisplayMsg: (toDisplayMsg: "block" | "none") => void,
	nameRef: React.RefObject<HTMLInputElement>, emailRef: React.RefObject<HTMLInputElement>,
	msgRef: React.RefObject<HTMLTextAreaElement>, isLoaded: boolean, setIsLoaded: (isLoaded: boolean) => void) {

	return (
		<div className='ctus-div'>
			<form action="">
				<input type="text" name="" ref={nameRef} className='ctus-ip' placeholder="Name" id="name" style={{
					width: "100%",
					marginBottom: "2em",
					padding: "15px"
				}}/>
				<input type="text" name="" ref={emailRef} className='ctus-ip' placeholder="Email" id="email" style={{
					width: "100%",
					marginBottom: "2em",
					padding: "15px"
				}}/>
				<textarea name="message" ref={msgRef} className='ctus-txt ctus-ip' placeholder="Message" id="message" />
				<p style={{
					display: toDisplayMsg
				}}>
					<img src={ require("../images/tick-green.png") } alt="Green tick" style={{
						width: "25px",
						marginRight: "1em"
					}} />
					Submitted successfully
				</p>
				<Button style={{
					width: "100%",
					borderRadius: "20px",
					backgroundColor: "#2e025a",
					marginTop: "2%"
				}} onClick={ () => {
					setIsLoaded(false);
					setTimeout(() => {
						handleSubmit(setToDisplayMsg, nameRef, emailRef, msgRef)
						setIsLoaded(true);
					}, 2000)
				} } className='ctus-submit-btn'>{ isLoaded ? "Process" : <Loader size={"20px"} /> }</Button>
			</form>
		</div>
	)
}

export default () => {  
	const [toDisplayModal, setToDisplayModal] = useState< "none" | "block" >("none");
	const [isLoaded, setIsLoaded] = useState<boolean>(true);

	const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
	const [innerWidth, setInnerWidth] = useState<number>(0);
    const [lowerWidthDisplay, setLowerWidthDisplay] = useState<"none" | "block">("block");
    const [textAlignValue, setTextAlignValue] = useState<"center" | "right">("center")

	useEffect(() => useEffectCallback(setInnerWidth, setLowerWidthDisplay, setTextAlignValue, setIsCollapsed, innerWidth));

	const nameRef = useRef<HTMLInputElement>(null);
	const emailRef = useRef<HTMLInputElement>(null);
	const msgRef = useRef<HTMLTextAreaElement>(null);

	return (
		<div style={{
			height: "inherit"
		}}>
			<Head.default title='SimpleValidator - Contact Us' />
			<Header lowerWidthDisplay={lowerWidthDisplay} textAlignValue={textAlignValue} />
				<div style={{
					display: "inline-flex",
					width: "100%",
					height: "64%"
				}}>
					<div style={{
						width: "50%",
						textAlign: "center",
						height: "50%"
					}}>{ contactUsIdentity() }</div>
					<div style={{
						width: "50%"
					}}>{ contactUsForm(toDisplayModal, 
							setToDisplayModal, 
							nameRef, 
							emailRef, 
							msgRef,
							isLoaded,
							setIsLoaded
							) 
					}</div>
				</div>	
			<Footer />
		</div>
	)
}