import FullScreen from "react-fullscreen-crossbrowser";
import { FullScreenProps } from "../../utils/Interfaces";
import { Button } from "react-bootstrap";
import { useEffect } from "react";

export default (props: FullScreenProps) => {

    return (
        <>
            <FullScreen
                enabled={props.enabled}
                onChange={(enabled: any) => {
                    if(!enabled) props.setFullScrennEnabled(enabled)
                }}
                >
                <div className='full-screenable-node'>
                    { props.child }
                </div>
            </FullScreen>
        </>
    );
}