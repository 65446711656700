import { CSSProperties } from "react";

export function codeDivCss(fullScreenEnabled: boolean): CSSProperties {
    var width = "90%";
    var left = "5%";
    var height = "max-content";

    if(fullScreenEnabled) {
        width = "100%";
        left = "0%";
        height = "100vh";
    }

    return {
        display: "block",
        backgroundColor: "white",
        width: width,
        margin: "unset",
        height: height,
        borderRadius: "5px",
        position: "relative",
        left: left
    }
}

export function linesCommonDiv(fullScreenEnabled: boolean, isReadOnlyView: boolean): CSSProperties {
    var maxHeight = "30em";
    var height = "max-content"

    if(fullScreenEnabled) {
        maxHeight = "unset";
        height = "inherit";
    }
    if(isReadOnlyView)
        height = "86.5%"

    return {
        textAlign: "justify",
        fontSize: "18px",
        fontFamily: "monospace",
        overflowY: "scroll",
        height: height,
        maxHeight: maxHeight,
        minHeight: "30em",
        border: "solid 1px"
    }
}

export function textAreaTextView(fullScreenEnabled: boolean): CSSProperties {
    if(fullScreenEnabled) {
        return {
            left: "unset", 
            width: "100%",
            height: "86.5%"
        }
    }

    return {
        left: "unset", 
        width: "100%"
    }
}