import { useEffect, useState } from "react";
import Toast, { displayMessageCallback } from "../common/Toast"
import { ValidateJsonObj } from "../../utils/Interfaces";
import { stringifyJson } from "../../utils/core/Validator";
import { Button } from "react-bootstrap";
import Loader from "../common/Loader";
import Code from "../code_views/Code";
import FullScreenView from "../common/FullScreenView";

export default () => {
    const [textContent, setTextContent] = useState("");
    const [formattedJson, setFormattedJson] = useState<ValidateJsonObj>({
        resultString: "",
        result: false,
        error: ""
    });
    const [toDisplayMsg, setToDisplayMsg] = useState(false);
    const [isLoaded, setIsLoaded] = useState(true);
    const [readyForScroll, setReadyForScroll] = useState(false);
    const [fullScreenEnabled, setFullScrennEnabled] = useState<boolean>(false);

    useEffect(() => {
        displayMessageCallback(toDisplayMsg, formattedJson, setToDisplayMsg, false)
    })
    return (
        <div className="main-screen-root-div" style={{
            textAlign: "left"
        }}>
            <Toast />
            <h2 className="main-content-header-h2">JSON To String / Stringify</h2>
            <p className="desc-p">Best online JSON To String / Stringify JSON data. Easy tool to stringify JSON data.
            </p>
            <textarea 
                id="content" 
                onChange={(e) => {setTextContent(e.target.value)}} 
                placeholder="Enter your JSON text here"
                onFocus={() => {
                    setReadyForScroll(false);
                }}
            ></textarea>
            <div style={{
                width: "fit-content",
                textAlign: "left",
                marginTop: "2%",
                position: "relative",
                left: "5%"
            }}>
                <Button style={{
                    width: "8em",
                    height: "2.5em"
                }}
                    className="process-btn" 
                    onClick={() => {
                        setIsLoaded(false);
                        setToDisplayMsg(true);
                        stringifyJson(textContent, setFormattedJson, setToDisplayMsg, setIsLoaded)
                        setReadyForScroll(true)
                    }}
                >
                    { isLoaded ? "Process" : <Loader /> }
                </Button>
            </div>
            {
                    formattedJson.result ? 
                    <FullScreenView child={
                        <Code 
                            fullScreenEnabled={fullScreenEnabled} 
                            setFullScrennEnabled={setFullScrennEnabled} 
                            readyForScroll={readyForScroll} 
                            title="JSON String" 
                            content={formattedJson.resultString ? formattedJson.resultString : ""} 
                            toShow={toDisplayMsg} 
                        />
                    } enabled={fullScreenEnabled} setFullScrennEnabled={setFullScrennEnabled} />
                    :   <></>
            }
        </div>
    )
}