export var toSmallAndRemoveSpaces = (text: string) => { return text.toLocaleLowerCase().replace(/ /g, ""); }

export var useEffectCallback = (setInnerWidth: (width: number) => void,
    setLowerWidthDisplay: (lowerWidthDisplay: "none" | "block") => void,
    setTextAlignValue: (textAlignValue: "right" | "center") => void,
    setIsCollapsed: (isCollapsed: boolean) => void,
    innerWidth: number
) => {
    if(window.innerWidth !== innerWidth)
        setInnerWidth(window.innerWidth)

    if(window.innerWidth <= 995) {
        setLowerWidthDisplay("none")
        setTextAlignValue("right")
        setIsCollapsed(true);
    } else {
        setLowerWidthDisplay("block")
        setTextAlignValue("center")
        setIsCollapsed(false);
    }

    window.addEventListener('resize', () => {
        if(window.innerWidth <= 995) {
            setLowerWidthDisplay("none")
            setTextAlignValue("right")
            setIsCollapsed(true);
        } else {
            setLowerWidthDisplay("block")
            setTextAlignValue("center")
            setIsCollapsed(false);
        }
    })
}