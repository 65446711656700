import Loader from "../../components/common/Loader";
import Toast, { displayMessageCallback } from "../../components/common/Toast";
import { useEffect, useState } from "react"
import { Button } from "react-bootstrap"
import { validateJson } from "../../utils/core/Validator";
import { ValidateJsonObj } from "../../utils/Interfaces";

export default () => {
    const [textContent, setTextContent] = useState("");
    const [jsonValidate, setJsonValidate] = useState<ValidateJsonObj>({
        result: false,
        error: ""
    });
    const [toDisplayMsg, setToDisplayMsg] = useState(false);
    const [isLoaded, setIsLoaded] = useState(true);

    useEffect(() => {
        displayMessageCallback(toDisplayMsg, jsonValidate, setToDisplayMsg)
    })

    return (
        <div className="main-screen-root-div">
            <Toast />
            <div style={{
                marginTop: "2em",
                textAlign: "left"
            }}>
                <h2 style={{
                    textAlign: "initial",
                    paddingLeft: "5%",
                    color: "rgb(46 2 90)",
                    fontFamily: "Montserrat"
                }}>JSON Formatter / Beautifier / Validator</h2>
                <p className="desc-p">Best Online JSON Formatter / JSON Beautifier / JSON Validator will validate JSON data.
                </p>
                <textarea 
                    id="content" 
                    onChange={(e) => {setTextContent(e.target.value)}} 
                    placeholder="Enter your JSON text here"
                ></textarea>
                <div style={{
                    width: "fit-content",
                    textAlign: "left",
                    marginTop: "2%",
                    position: "relative",
                    left: "5%"
                }}>
                    <Button style={{
                        width: "8em",
                        height: "2.5em"
                    }}
                        className="process-btn" 
                        onClick={() => {
                            setIsLoaded(false);
                            setToDisplayMsg(true);
                            validateJson(textContent, setJsonValidate, setToDisplayMsg, setIsLoaded)
                        }}
                    >
                        { isLoaded ? "Process" : <Loader /> }
                    </Button>
                </div>
            </div>
        </div>
    )
}