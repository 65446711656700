import Link from "./common/Link"

export default () => {
    return (
        <footer>
            <Link href="/" className="cus-lnk" text="Home" />
            <Link href="/json/Formatter" className="cus-lnk" text="Json Formatter" />
            <Link href="/json/Validator" className="cus-lnk" text="Json Validator" />
            <Link href="/json/Compare" className="cus-lnk" text="Json Compare" />
            <Link href="/json/ToString" className="cus-lnk" text="Json To String" />
            <Link href="/ContactUs" className="cus-lnk" text="Contact Us" />
            {/* <Link href="https://github.com/SubhenduShekhar/jsonvalidator-ui/issues/new" text="Issues" className="cus-lnk" /> */}
        </footer>
    )
}