import { Bounce, Slide, ToastContainer, ToastOptions, toast} from "react-toastify";
import { ValidateJsonObj } from "../../utils/Interfaces";

export default () => {	
	return (
		<ToastContainer
			position="top-center"
			autoClose={5000}
			hideProgressBar={false}
			newestOnTop={false}
			closeOnClick
			rtl={false}
			pauseOnFocusLoss
			draggable
			pauseOnHover
			theme="light"
			transition={Bounce}
		/>
	)
}

export var ToastCustomOptions = (onToastClose: () => void): ToastOptions =>{ 
	return {
		position: "top-center",
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: "colored",
		style: {
			width: "25em"
		},
		transition: Bounce,
		onClose: onToastClose,
		onOpen: () => { setTimeout(onToastClose, 200) }
	}
}

export var notify = (message: string) => { toast(message) }

export var success = (message: string, setToDisplayFlag: (toDisplay: boolean) => void) => { 
	toast.success(message, ToastCustomOptions(() => {
		setToDisplayFlag(false)
	})); 
}

export var error = (message: string, setToDisplayFlag: (toDisplay: boolean) => void) => { 
	toast.error(message, ToastCustomOptions(() => setToDisplayFlag(false))); 
}

export var info = (message: string) => {
	toast.info(message, {
		position: "bottom-right",
		autoClose: 2000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: 1,
		theme: "colored",
		transition: Slide,
		onOpen: () => { setTimeout(() => {}, 200) }
	});
}

export function displayMessageCallback(
		toDisplayMsg: boolean, 
		resultObj: ValidateJsonObj, 
		setToDisplayMsg: (toDisplay: boolean) => void, 
		toDisplaySuccess?: boolean
	) {
	if(toDisplaySuccess === undefined) toDisplaySuccess = true;
	
	if(toDisplayMsg) {
		if(resultObj.error && (resultObj.error !== "") && !resultObj.result)
			error(resultObj.error, setToDisplayMsg)
		else if(resultObj.result && toDisplaySuccess)
			success("This JSON is valid under RFC 8259", setToDisplayMsg)
	}
}