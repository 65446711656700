import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { CustomDisplayType, ToolbarProps, ViewTypes } from "../../utils/Interfaces"
import Image from "../common/Image"
import { info } from "../common/Toast"
import FullScreenView from "../common/FullScreenView";
import ReactDOM from 'react-dom/client';
import { Button } from "react-bootstrap";
import { Tooltip } from "@mui/material";
const defaults = require("../../metadata/defaults.json");

function handleSearchClick(setViewType: Dispatch<SetStateAction<string>> | undefined, 
    setDDText: Dispatch<SetStateAction<string>> | undefined,
    setDispSearch: Dispatch<SetStateAction<"block" | "none">>, 
    displaySearch: "none" | "block") {
    
    if(displaySearch === "block")
        setDispSearch("none")
    else {
        info(defaults.ViewChangedToReadOnly);
    
        setTimeout(() => {
            if(setViewType) setViewType(ViewTypes.ReadOnly)
            if(setDDText) setDDText(ViewTypes.ReadOnly)

            if(displaySearch === "none")
                setDispSearch("block")
        }, 1000);
    }
}

function Search(props: ToolbarProps) {
    return (
        <Tooltip title="Search" arrow>
            <a onClick={() => 
                handleSearchClick(
                    props.setViewType, 
                    props.setDDText,
                    props.setDispSearch,
                    props.displaySearch
                )}
                className="tb-item">
                <Image src={require("../../images/search.png")} />
            </a>
        </Tooltip>
    )
}

function Copy(content: string) {
    return (
        <Tooltip title="Copy" arrow>
            <a
                onClick={() => {
                    navigator.clipboard.writeText(content)
                    .then(() => info(defaults.SuccessCopyToCB))
                }}
                className="tb-item">
                        <Image style={{
                        width: "25px"
                    }} src={require("../../images/copy.png")} />            
            </a>
        </Tooltip>
    )
}

function FullScreen(setFullScreenEnabled: Dispatch<SetStateAction<boolean>>, fullScreenEnabled: boolean) {
    return (
        <Tooltip title="Full Screen" arrow>
            <a
                onClick={() => setFullScreenEnabled(!fullScreenEnabled) }
                className="tb-item">
                <Image style={{
                    width: "25px"
                }} src={
                    fullScreenEnabled ? 
                    require("../../images/exit-full-screen.png") :
                    require("../../images/full-screen.png")
                } />
            </a>
        </Tooltip>
    )
}

export default (props: ToolbarProps) => {

    return (
        <>
            <div className="tools-menu">
                <div style={{
                    display: "inline",
                    marginRight: "2%"
                }}>
                    <Search 
                        setDispSearch={props.setDispSearch} 
                        displaySearch={props.displaySearch} 
                        setViewType={props.setViewType} 
                        setDDText={props.setDDText}
                        fullScreenEnabled={props.fullScreenEnabled ? props.fullScreenEnabled : false}
                        setFullScrennEnabled={props.setFullScrennEnabled}
                    />
                    {
                        props.content ?
                            <>
                                { Copy(props.content) }
                                { FullScreen(props.setFullScrennEnabled, props.fullScreenEnabled) }
                            </> :
                            <></>
                    }
                </div>
            </div>
        </>
    )
}