import { Menu, MenuItem, Sidebar, SubMenu } from "react-pro-sidebar";
import { toSmallAndRemoveSpaces } from "../utils/Utils";
import { useEffect, useState } from "react";
import { SidebarProps } from "../utils/Interfaces";
const sidebarConfig = require('../metadata/sidebar.json');

function getAllSubMenuLabels(): string[] {
    return Object.keys(sidebarConfig);
}

function getMenuItemsByLabel(label: string) {
    let items: any[] = [];
    for(let i = 0; i < sidebarConfig[label].length; i ++)
        items.push(<MenuItem 
            key={toSmallAndRemoveSpaces(sidebarConfig[label][i])}
            href={sidebarConfig.Links[sidebarConfig[label][i]]}
            >{sidebarConfig[label][i]}</MenuItem>)
    return items;
}

function handleExpandColapse() {

}

export default (props: SidebarProps) => {

    return (
        <>
            <Sidebar collapsed={props.isCollapsed} style={{
                height: "auto",
                position: "absolute"
            }} className="sidebar">
                <Menu className="sidebar-menu">
                    {
                        getAllSubMenuLabels().filter(eachItem => (eachItem !== "Links")).map(eachLabel => {
                            return (
                                <SubMenu label={eachLabel}>
                                    {
                                        getMenuItemsByLabel(eachLabel)
                                    }
                                </SubMenu>
                            )
                        })
                    }
                </Menu>
            </Sidebar>
        </>
    )
}