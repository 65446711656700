import Sidebar from '../../components/Sidebar'
import JsonValidators from "../../components/validators/JsonValidator"
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { useEffect, useState } from 'react'
import { useEffectCallback } from '../../utils/Utils'
import Description from '../../components/common/Description'
const pages = require('../../metadata/pages.json');

export default () => {
	const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
	const [innerWidth, setInnerWidth] = useState<number>(0);
    const [lowerWidthDisplay, setLowerWidthDisplay] = useState<"none" | "block">("block");
    const [textAlignValue, setTextAlignValue] = useState<"center" | "right">("center")

	useEffect(() => useEffectCallback(setInnerWidth, setLowerWidthDisplay, setTextAlignValue, setIsCollapsed, innerWidth));

    return (
		<div style={{
			height: "fit-content"
		}}>
			<Header lowerWidthDisplay={lowerWidthDisplay} textAlignValue={textAlignValue} />
			<Sidebar isCollapsed={isCollapsed} />
			<JsonValidators />
			<Description 
				aboutDescription={pages.json.validator.description.About} 
				moreAboutJson={pages.json.general.MoreAboutJson} 
				standards={pages.json.general.Standards}
				faqs={pages.json.general.FAQs} />
			<Footer />
      	</div>
    )
}