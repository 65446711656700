import { Box } from "@mui/material"
import { SimpleTreeView, TreeItem } from "@mui/x-tree-view"

var constructKey = (rootJsonObject: any, key: string) => { return key + " {" + Object.keys(rootJsonObject).length + "}"; }

var key = 1;

function getTreeViewRecursively(jsonObject: any, previousKey: string) {
    if(typeof jsonObject === "object") {
        return (
            <TreeItem itemId={previousKey + ++key} label={previousKey} style={{
                color: "gray",
                fontFamily: "monospace"
            }}>
            {
                Object.keys(jsonObject).map(eachKey => {
                    return (getTreeViewRecursively(jsonObject[eachKey], constructKey(jsonObject[eachKey], eachKey)))
                })
            }
            </TreeItem>
        )
    }
    else 
        return (
            <div style={{
                display: "flex"
            }}>
                <span style={{
                    fontSize: "15px"
                }}>{previousKey.split(" {")[0]}</span>
                <TreeItem itemId={ jsonObject + ++key } label={jsonObject} style={{
                    color: "#333504",
                    textWrap: "nowrap",
                    fontFamily: "monospace"
                }}/>
                <span className="tree-span">{typeof jsonObject}</span>
            </div>
        )
}

function getTreeView(jsonObject: any) {
    try {
        return (
            <SimpleTreeView>
                { getTreeViewRecursively(jsonObject, constructKey(jsonObject, "Root")) }
            </SimpleTreeView>
        )
    }
    catch {
        return <>Tree View not possible for this item</>
    }
}

export default (props: any) => {
    key = 1;

    return (
        <Box sx={{ minHeight: 352, minWidth: 250 }}>
            { getTreeView(JSON.parse(props.textContent)) }
        </Box>
    )
}