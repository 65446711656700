import React, { Dispatch, ReactElement, SetStateAction } from "react";
import { LengthType } from "react-spinners/helpers/props";

export interface MenuItem {
    href?: string,
    text: string,
    className?: string
}

export interface ValidateJsonObj {
    resultString?: string,
    result: boolean,
    error?: string
}

export interface TextViewProp {
    lines: string[],
    linesToHighlight?: number[],
    searchEntity?: string
}

export interface CodeProp {
    title: string,
    content: string,
    toShow: boolean,
    readyForScroll: boolean,
    width?: string,
    showViewAsButton?: boolean,
    headerLeftMargin?: string,
    linesToHighlight?: number[],
    demandedViewType?: string,
    fullScreenEnabled: boolean, 
    setFullScrennEnabled: Dispatch<SetStateAction<boolean>>
}

export interface ProcessBtnProps {
    textContent: string,
    setIsLoaded: (isLoaded: boolean) => void,
    setToDisplayMsg: (toDisplayMsg: boolean) => void,
    setFormattedJson: (formattedJson: ValidateJsonObj) => void,
    isLoaded: boolean,
    onclickCallBack: (
        textContent: string, 
        setFormattedJson: (formattedJson: ValidateJsonObj) => void,
        setToDisplayMsg: (toDisplayMsg: boolean) => void,
        setIsLoaded: (isLoaded: boolean) => void) => void
}

export interface JSONCompareResultObj {
    notIn1: string[],
    notIn2: string[],
    isValid: boolean,
    lines1: number[],
    lines2: number[],
    jsonText1: string,
    jsonText2: string
}

export interface DifferenceSummaryProp {
    difference: JSONCompareResultObj
}

export interface LoaderProps {
    size?: LengthType | undefined
}

export interface ImageProps {
    src: string,
    style?: React.CSSProperties,
    alt?: string,
    className?: string
}

export interface LinkProps {
    href: string,
    className?: string,
    text: string,
    style?: React.CSSProperties,
    target?: "_blank" | "_self"
}

export interface HeadProps {
    title: string
}

export interface HeaderProps {
    lowerWidthDisplay: "none" | "contents" | "block",
    textAlignValue: "center" | "right"
}

export interface SidebarProps {
    isCollapsed: boolean
}

interface Faqs {
    question: string,
    answer: string
}

export interface DescriptionProps {
    aboutDescription: string[],
    moreAboutJson: {
        description: string,
        links: any
    },
    standards: {
        description: string,
        links: any
    },
    faqs: Faqs[]
}

export enum ViewTypes {
    ReadOnly = "Read Only",
    ObjectView = "Object View",
    TextView = "Text View"
}

export type CustomDisplayType = "block" | "none";

export interface ToolbarProps {
    setViewType?: Dispatch<SetStateAction<string>>,
    setDDText?: Dispatch<SetStateAction<string>>,
    setDispSearch: Dispatch<SetStateAction<CustomDisplayType>>,
    displaySearch: CustomDisplayType,
    content?: string,
    fullScreenEnabled: boolean, 
    setFullScrennEnabled: Dispatch<SetStateAction<boolean>>
}

export interface Position {
    start: number,
    end: number
}

export interface JsonSearchResult {
    lineNumber: number,
    position: Position[]
}

export interface FullScreenProps {
    enabled: boolean; 
    setFullScrennEnabled: Dispatch<SetStateAction<boolean>>,
    child: ReactElement<any, any>
}