import { JSONCompareResultObj, ValidateJsonObj } from "../Interfaces";
import { Dispatch, SetStateAction } from "react";
var errorMsg = require("../../metadata/errorMsg.json");

function jsonValidate(content: string): ValidateJsonObj {
    try {
        JSON.parse(content);
        return {
            result: true
        };
    }
    catch(e: any) {
        return {
            result: false,
            error: e.message
        }
    }
}

export function validateJson(content: string, 
    setJsonValidate: React.Dispatch<React.SetStateAction<ValidateJsonObj>>, 
    setToDisplayMsg: (toDisplay: boolean) => void, 
    setIsLoaded: (isLoaded: boolean) => void) {
    var resultObj: ValidateJsonObj = jsonValidate(content);

    if(resultObj.error && Object.keys(errorMsg).includes(resultObj.error))
        resultObj.error = errorMsg[resultObj.error];

    setToDisplayMsg(true);
    setJsonValidate(resultObj);
    setIsLoaded(true);
    
    return resultObj;
}

export function formatJson(content: string, setFormattedJson: Dispatch<SetStateAction<ValidateJsonObj>>, 
    setToDisplayMsg: (toDisplay: boolean) => void, 
    setIsLoaded: (isLoaded: boolean) => void) {
    var resultObj: ValidateJsonObj = jsonValidate(content);
    var formattedJson: ValidateJsonObj;

    if(resultObj.result)
        formattedJson = {
            resultString: JSON.stringify(JSON.parse(content), null, 4),
            result: resultObj.result,
            error: ""
        }
    else 
        formattedJson = {
            result: resultObj.result,
            error: resultObj.error ? resultObj.error : ""
        }
    if(formattedJson.error && Object.keys(errorMsg).includes(formattedJson.error))
        formattedJson.error = errorMsg[formattedJson.error];

    setFormattedJson(formattedJson);
    setToDisplayMsg(true);
    setIsLoaded(true);
}

export function jsonCompare(jsonString1: string, jsonString2: string): JSONCompareResultObj {

    jsonString1 = JSON.stringify(JSON.parse(jsonString1), null, 4);
    jsonString2 = JSON.stringify(JSON.parse(jsonString2), null, 4);

    var jsonStringArr1: string[] = jsonString1.split("\n");
    var jsonStringArr2: string[] = jsonString2.split("\n");

    var notIn2: string[] = [];
    var notIn1: string[] = [];
    var lines2: number[] = [];
    var lines1: number[] = [];
    var isValid: boolean = false;

    for(let i = 0; i < jsonStringArr1.length; i ++) {
        if(jsonStringArr1[i].trim() === "{" || jsonStringArr1[i].trim() === "}")
            continue;

        if(!jsonStringArr2.includes(jsonStringArr1[i])) {
            notIn2.push(jsonStringArr1[i])
            lines1.push(i);
        }
    }
    for(let i = 0; i < jsonStringArr2.length; i ++) {
        if(jsonStringArr2[i].trim() === "{" || jsonStringArr2[i].trim() === "}")
            continue;

        if(!jsonStringArr1.includes(jsonStringArr2[i])) {
            notIn1.push(jsonStringArr2[i])
            lines2.push(i)
        }
    }
    if(notIn1.length == 0 && notIn2.length == 0)
        isValid = true

    return {
        notIn1: notIn1,
        notIn2: notIn2,
        lines1: lines1,
        lines2: lines2,
        jsonText1: jsonString1,
        jsonText2: jsonString2,
        isValid: true
    }
}

export function stringifyJson(content: string, 
    setStrigifiedJson: Dispatch<SetStateAction<ValidateJsonObj>>,
    setToDisplayMsg: (toDisplay: boolean) => void, 
    setIsLoaded: (isLoaded: boolean) => void) {
    var resultObj: ValidateJsonObj = validateJson(content, setStrigifiedJson, setToDisplayMsg, setIsLoaded);
    
    if(resultObj.result)
        resultObj.resultString = "\"" + JSON.stringify(JSON.parse(content)).replace(/\"/g, "\\\"") + "\"";
    
    if(resultObj.error && Object.keys(errorMsg).includes(resultObj.error))
        resultObj.error = errorMsg[resultObj.error];

    setStrigifiedJson(resultObj);
    setToDisplayMsg(true);
    setIsLoaded(true);
}